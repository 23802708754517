<!--收到后端传来的sessioni/设置cookie-->
<!--可以在这个页面尝试做一些spa初始化-->
<!--是否可以把用户的头像啥的都在这边保存？店名的初始化？浏览器的初始化？等等都可以存入到vuex-->
<template>
    <div></div>
</template>

<script>
  import { getLocalStorage, setLocalStorage } from '../../utils/localStorage'
  import { globalConfig } from '../../utils/mixin'
  import { getCookie, setCookie, setCookieDefault } from '../../utils/cookie'
  import { initSrcChannel } from '../../utils/tools'
  // import { standardTimeIsOver } from '../../utils/tools'

  export default {
    name: 'AuthNotify',
    mixins: [globalConfig],
    methods: {
    },
    mounted () {
      const that = this
      const sessionId = this.$route.params.phpsessid
      if (sessionId) {
        //  测试环境
        setCookieDefault('PHPSESSID', sessionId) // 设置cookie解决跨域问题
      } else {
        //  正式环境
      }
      // 浏览器判断
      const userAgent = navigator.userAgent.toLowerCase()
      setLocalStorage('userAgent', userAgent)
      if (getCookie('isCcbApp') == 1) {
        setLocalStorage('currentBrowser', 'ccbApp')
      } else if (getCookie('isByteDance' == 1)) {
        setLocalStorage('currentBrowser', 'byteDance')
      } else if (userAgent.indexOf('miniprogram') !== -1) {
        setLocalStorage('currentBrowser', 'weixinApp')
      } else if (userAgent.indexOf('micromessenger') !== -1) {
        setLocalStorage('currentBrowser', 'weixin')
      } else if (userAgent.indexOf('unionpay') !== -1) {
        setLocalStorage('currentBrowser', 'unionpay')
      } else {
        setLocalStorage('currentBrowser', '')
      }
      // 获取授权之前的链接
      const beforeLoginUrl = getLocalStorage('beforeLoginUrl')
      // 在这个页面是否可以做一些页面的初始化。这个页面只是回调页面，没有存放具体信息，具体参数信息在beforeLoginUrl里面
      this.axios.post(this.apiUrl + 'mall/init/vueV2').then((response) => {
        const data = response.data
        if (data.status === 1) {
          const result = data.data
          // 这边用cookie而不用localstorage的主要考虑，这些属性都是每次进入要更新的，设置生命周期为session正好伴随授权。
          // 需要注意在完成授权以后，用户完成绑定或者成为分销员，需要实时去变动这些参数的值，不然他在这一次登录过程中都无法享受这个权限
          // 在这个组件设置vuex有极大风险，用户刷新并不会进入authnotify，导致vuex失效
          // 不要把是否绑定写在缓存，都要实时去判断。因为用户在一次浏览里面变成绑定用户是大概率时间，而此时可能缓存里面还是写着isbind=0没实时更新
          setCookie('userAuth', 1, 0.03)
          setCookieDefault('isDistributionMember', result.isDistributionMember ? 1 : 0)
          setLocalStorage('currentUserChannelId', result.currentUserChannelId)
          setLocalStorage('channelSource', result.channelSource)
          setLocalStorage('signPackage', JSON.stringify(result.signPackage)) // 授权的时候把参数存起来，后面每一次进入页面都要通过这个参数设置默认配置
          initSrcChannel(result.srcChannel, result.shopName)
          that.$router.replace(beforeLoginUrl) // 跳回去授权前的页面，这必须是最后一句执行
        }
      })
    }
  }
</script>

<style scoped>

</style>
